import GradientText from '@/components/GradientText';
import { Layout } from '@/components/Layout';
import Helmet from 'react-helmet';
import { Widget } from '@/components/Widget';
import { Box, Button, Card, CardContent, Container, Grid, styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import ReactTypingEffect from 'react-typing-effect';
import { FreeTrialCTA } from '@/components/FreeTrialCTA';

const IntroSection = styled('main')`
  display: flex;
  min-height: calc(100vh - 100px);
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacing(7)};
`;

const Center = styled('div')`
  margin: auto auto;
`;

const Title = styled(Typography)``;

const Section = styled('section')<{ $dark?: boolean; height?: string }>`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing(8)} ${theme.spacing(2)}`};
  ${({ height }) => height && `height: ${height};`}
  background-color: ${({ theme, $dark }) =>
    $dark ? theme.palette.primaryDark[800] : theme.palette.primaryDark[700]};
`;

const FeatureCard = ({
  children,
  emoji,
  title,
}: {
  children: ReactNode;
  emoji: string;
  title: string;
}) => (
  <Card variant="outlined">
    <CardContent sx={{ p: 3 }}>
      <Typography variant="h2" component="h5">
        {emoji}
      </Typography>
      <Typography variant="h3" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="body1">{children}</Typography>
    </CardContent>
  </Card>
);

const FeatureGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: ${({ theme }) => theme.spacing(3)};
  justify-content: space-between;
`;

export default function Home() {
  return (
    <Layout>
      <Container maxWidth="md">
        <Helmet title="PollPilot | Embeddable Polls and Reactions" />
        <IntroSection>
          <Center>
            <Title textAlign="center" gutterBottom variant="h1">
              Know how your customers feel about your <Box />
              <ReactTypingEffect
                text={['features.', 'blog posts.', 'ideas.']}
                displayTextRenderer={(text, i) => {
                  return <GradientText key={i}>{text}</GradientText>;
                }}
                typingDelay={10}
                eraseDelay={2000}
                speed={100}
                eraseSpeed={90}
              />
            </Title>
            <Typography textAlign="center" variant="subtitle1">
              Get valuable insights into what your customers think by asking the right questions at
              the right time.
            </Typography>
            <Box sx={{ display: 'flex' }} mt={8}>
              <Button
                size="large"
                sx={{ m: 'auto' }}
                variant="contained"
                color="primary"
                href="https://app.pollpilot.io"
              >
                Start your 7-day free trial
              </Button>
            </Box>
            <Typography sx={{ mt: 2 }} textAlign="center" variant="body2">
              No credit card required
            </Typography>
          </Center>
        </IntroSection>
      </Container>
      <Section sx={{ height: { md: '500px', sm: 'auto' } }}>
        <Container sx={{ height: '100%' }} maxWidth="lg">
          <Grid sx={{ height: '100%' }} container>
            <Grid sx={{ m: 'auto', mb: { xs: 4, sm: 'auto' } }} item xs={12} sm={4}>
              <Typography variant="subtitle1" color="primary.main">
                Feedback
              </Typography>
              <Typography variant="h2" gutterBottom>
                Ask your customers what you want to know{' '}
                <GradientText>at the right time.</GradientText>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Looking at your analytics will only bring you so far.
              </Typography>
              <Typography variant="body1">
                By asking for feedback, you will understand what your customers think about your
                features.
              </Typography>
            </Grid>
            <Grid item sx={{ m: 'auto', minHeight: '300px' }} sm={8} xs={12}>
              <Widget id="R9hQm2I38qxzJMG" />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section $dark sx={{ height: { md: '500px', sm: 'auto' } }}>
        <Container sx={{ height: '100%' }} maxWidth="lg">
          <Grid sx={{ height: '100%' }} container>
            <Grid sx={{ m: 'auto', minHeight: '220px' }} item sm={12} md={8}>
              <Widget id="JTw0KQotDE2lSQw" />
            </Grid>
            <Grid sx={{ m: 'auto' }} item md={4} sm={12}>
              <Typography variant="subtitle1" color="primary.main">
                Reactions
              </Typography>
              <Typography variant="h2" gutterBottom>
                A reaction is worth a <GradientText>thousand words...</GradientText>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Get quick feedback through reactions that tell you how your customer feels in
                real-time.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container maxWidth="lg">
          <Grid container>
            <Grid sx={{ display: { xs: 'block', md: 'none' }, mb: 3 }} item xs={12}>
              <img src="/app_screenshot.png" height="300px" alt="App screenshot" />
            </Grid>
            <Grid container item sm={6} xs={12}>
              <Center>
                <Typography variant="subtitle1" color="primary.main">
                  Analytics
                </Typography>
                <Typography variant="h2" gutterBottom>
                  Get the <GradientText>full</GradientText> picture.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Analyze the responses of your polls over time and get a detailed list of all
                  responses with emails on the analytics page.
                </Typography>
              </Center>
            </Grid>
            <Grid sx={{ display: { xs: 'none', md: 'block' } }} item sm={6}>
              <img src="/app_screenshot.png" height="700px" alt="App screenshot" />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section $dark>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12}>
              <Typography textAlign="center" variant="subtitle1" color="primary.main">
                Features
              </Typography>
              <Typography textAlign="center" variant="h2" gutterBottom>
                Everything you need to <GradientText>understand </GradientText>your customers.
              </Typography>
              <Box mt={9} />
              <FeatureGrid>
                <FeatureCard emoji="🤪😍😡" title="Reactions">
                  <Typography gutterBottom>
                    Get contextual feedback for your content and features.
                  </Typography>
                  <Typography>You can follow up with more detailed questions.</Typography>
                </FeatureCard>
                <FeatureCard emoji="📊" title="Polls">
                  <Typography gutterBottom>
                    Get contextual feedback for your content and features.
                  </Typography>
                  <Typography>You can follow up with more detailed questions.</Typography>
                </FeatureCard>
                <FeatureCard emoji="⚡️" title="Speed">
                  <Typography gutterBottom>
                    The embedded polls have a small footprint and don't load until your page has
                    finished loading.
                  </Typography>
                  <Typography>Your website will load as fast as always.</Typography>
                </FeatureCard>
                <FeatureCard emoji="🔍" title="Insights">
                  <Typography>
                    Get an overview of the state of your content on the dashboard and identify
                    issues with the detailed view.
                  </Typography>
                </FeatureCard>
                <FeatureCard emoji="💌" title="Emails">
                  <Typography>
                    Add an email form after a poll or reaction so you can reach out for further
                    questions.
                  </Typography>
                </FeatureCard>
                <FeatureCard emoji="💻" title="Shape the future">
                  <Typography gutterBottom>This product is still in the making.</Typography>
                  <Typography>
                    As an early adopter, you don't only support the creator, but you'll also give
                    valuable feedback that will define how the product evolves.
                  </Typography>
                </FeatureCard>
              </FeatureGrid>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <FreeTrialCTA />
      </Section>
    </Layout>
  );
}
